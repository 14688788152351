const $ = require('jquery');
const modernizr = require('./modernizr.min.js');
const Buehne = require('../../modules/2_15_1_Buehne/js/buehne');
const ImageArticle = require('../../modules/2_15_5_Image_Article/js/image_article');
const SplitScreen = require('../../modules/2_15_9_Split_Screen/js/split_screen');
const ImageZoom = require('../../modules/2_15_10_Image_Zoom_Slider/js/image_zoom');
const YoutubeVideo = require('../../modules/2_15_11_Video/js/video');
const Carousel = require('../../modules/2_15_12_Carousel/js/carousel');
const DualSliding = require('../../modules/2_15_13_Dual_Sliding/js/dual_sliding');
const Timeline = require('../../modules/2_15_14_Timeline/js/timeline');
const Menu = require('../../modules/header/js/menu');
const CC = require('../../modules/cookie_banner/js/cc');
const Filter = require('./filter');
const CustomSearchIdmedia = require('../../modules/2_15_15_Google_Maps/js/CustomSearchIdmedia');

window.App = {};

App = {
  isTouchDevice: 'ontouchstart' in document.documentElement,

  init: function() {
    this.checkTouchDevices();

    Menu.init();

    let isExperienceEditor = function () {
      if (typeof Sitecore == 'undefined'){   
        return false;   
      }
   
      return (Sitecore && Sitecore.PageModes && Sitecore.PageModes.PageEditor);
    }

    if (typeof window.isExperienceEditor == 'undefined') {
      window.isExperienceEditor = isExperienceEditor;
    }
    
    CC.init();
    Buehne.init();
    ImageZoom.init();
    Carousel.init();
    DualSliding.init();
    Timeline.init();
    YoutubeVideo.init();

    if (!Modernizr.objectfit ) {
      // ie11 & edge object-fit workaround
      ImageArticle.init('.image_article__wrapper__item, .image_article_prominent__wrapper__item');
    }

    new Filter('.teaser-grid-filter', '.teaser-grid-list');
    

    if(!this.isTouchDevice) {
      SplitScreen.init();
    }
  },

  checkTouchDevices: function() {
    if(this.isTouchDevice) {
      $('body').addClass('touch');
    } else {
      $('body').addClass('no-touch');
    }
  }

};

$(() => {
  App.init();
});